<template>
	<div class="mentions">
		<div class="container">
			<h1>Mentions légales</h1>
			<h2>INFORMATIONS LEGALES</h2>
			<p>Effet Animal</p>
			<p>Numéro SIRET : 880 072 814 00010</p>
			<p>Siège social : 2568 CHE DE DRAGUIGNAN 83440 FAYENCE</p>
			<p>N° de téléphone : 06 19 45 65 95</p>
			<h2>HEBERGEMENT</h2>
			<p>OVH.com</p>
			<p>SAS au capital de 10 069 020 €</p>
			<p>RCS Lille Métropole : 424 761 419 00045</p>
			<p>Code APE 2620Z</p>
			<p>N° TVA : FR 22 424 761 419</p>
			<p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
			<h2>GESTION DES DONNEES PERSONNELLES</h2>
			<p>
				En France, les données personnelles sont notamment protégées par la loi
				n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article
				L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
			</p>
			<p>
				Sur le site Effet Animal le propriétaire du site ne collecte des
				informations personnelles relatives à l'utilisateur que pour le besoin
				de certains services proposés par le site Effet Animal.
			</p>
			<p>
				L'utilisateur fournit ces informations en toute connaissance de cause,
				notamment lorsqu'il procède par lui-même à leur saisie. Il est alors
				précisé à l'utilisateur du site Effet Animal l’obligation ou non de
				fournir ces informations.
			</p>
			<p>
				Conformément aux dispositions des articles 38 et suivants de la loi
				78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
				libertés, tout utilisateur dispose d’un droit d’accès, de rectification,
				de suppression et d’opposition aux données personnelles le concernant.
				Pour l’exercer, adressez votre demande à Effet Animal par email : email
				du créateur ou en effectuant sa demande écrite et signée, accompagnée
				d’une copie du titre d’identité avec signature du titulaire de la pièce,
				en précisant l’adresse à laquelle la réponse doit être envoyée. Aucune
				information personnelle de l'utilisateur du site Effet Animal n'est
				publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue
				sur un support quelconque à des tiers.
			</p>
			<p>
				Seule l'hypothèse du rachat du site Effet Animal au propriétaire du site
				et de ses droits permettrait la transmission des dites informations à
				l'éventuel acquéreur qui serait à son tour tenu de la même obligation de
				conservation et de modification des données vis à vis de l'utilisateur
				du site Effet Animal.
			</p>
			<h2>LIENS HYPERTEXTES ET COOKIES</h2>
			<p>
				Le site Effet Animal contient un certain nombre de liens hypertextes
				vers d’autres sites (partenaires, informations …) mis en place avec
				l’autorisation du propriétaire du site. Cependant, le proprietaire du
				site n’a pas la possibilité de vérifier le contenu des sites ainsi
				visités et décline donc toute responsabilité de ce fait quand aux
				risques éventuels de contenus illicites.
			</p>
			<p>
				L’utilisateur est informé que lors de ses visites sur le site Effet
				Animal, un ou des cookies sont susceptible de s’installer
				automatiquement sur son ordinateur. Un cookie est un fichier de petite
				taille, qui ne permet pas l’identification de l’utilisateur, mais qui
				enregistre des informations relatives à la navigation d’un ordinateur
				sur un site. Les données ainsi obtenues visent à faciliter la navigation
				ultérieure sur le site, et ont également vocation à permettre diverses
				mesures de fréquentation. Le paramétrage du logiciel de navigation
				permet d’informer de la présence de cookie et éventuellement, de refuser
				de la manière décrite à l’adresse suivante : www.cnil.fr.
			</p>
			<p>
				Le refus d’installation d’un cookie peut entraîner l’impossibilité
				d’accéder à certains services. L’utilisateur peut toutefois configurer
				son ordinateur de la manière suivante, pour refuser l’installation des
				cookies : Sous Internet Explorer : onglet outil / options internet.
				Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
				Validez sur Ok. Sous Chrome : menu/ paramètres avancés. Cliquez sur
				Paramètres du contenu/ Cookies et choisissez Bloquer les cookies.
				Validez sur Ok. Cookies GOOGLE :
				<a
					href="https://policies.google.com/technologies/partner-sites"
					target="_blank"
					>En savoir plus !
					<i class="fa-solid fa-arrow-up-right-from-square"></i
				></a>
			</p>
			<h2>PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS</h2>
			<p>
				Le proprietaire du site est propriétaire des droits de propriété
				intellectuelle ou détient les droits d’usage sur tous les éléments
				accessibles sur le site, notamment les textes, images, graphismes, logo,
				icônes, sons, logiciels... Toute reproduction, représentation,
				modification, publication, adaptation totale ou partielle des éléments
				du site, quel que soit le moyen ou le procédé utilisé, est interdite,
				sauf autorisation écrite préalable à l'email :
				contactpro@anthonycollette.fr Toute exploitation non autorisée du site
				ou de l’un quelconque de ces éléments qu’il contient sera considérée
				comme constitutive d’une contrefaçon et poursuivie conformément aux
				dispositions des articles L.335-2 et suivants du Code de Propriété
				Intellectuelle.
			</p>
			<h2>SERVICES FOURNIS</h2>
			<p>
				Le site Effet Animal a pour objet de fournir une information concernant
				l’ensemble des activités de la société. Le proprietaire du site
				s’efforce de fournir sur le site Effet Animal des informations aussi
				précises que possible. Toutefois, il ne pourra être tenue responsable
				des omissions, des inexactitudes et des carences dans la mise à jour,
				qu’elles soient de son fait ou du fait des tiers partenaires qui lui
				fournissent ces informations. Tous les informations proposées sur le
				site Effet Animal sont données à titre indicatif, sont non exhaustives,
				et sont susceptibles d’évoluer. Elles sont données sous réserve de
				modifications ayant été apportées depuis leur mise en ligne.
			</p>
			<h2>CREATEUR</h2>
			<p>Anthony COLLETTE</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Mentions',
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/utils/_variables.scss';
@import '../assets/scss/utils/_mixins.scss';

h2 {
	font-weight: 600;
	text-decoration: underline;
	&:not(:first-of-type) {
		margin-top: 50px;
	}
}
a {
	color: $color-001;
	i {
		font-size: $font-sm;
	}
}
</style>
